import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { defaultTo, isBoolean, isNil, isArray, isString, isEmpty, isObject } from 'lodash';
import { Chip, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

dayjs.extend(utc);
dayjs.extend(timezone);

const THIS_VIN_TXT = { single: 'this VIN', plural: 'these VINs' };

// Data Table
export const MIN_COLUMN_WIDTH = 100;

export const getDataTableRowValue = (value, placeholder) => {
  if (!(isNil(value) || (isString(value) && value.trim() === ''))) {
    return value;
  }

  return defaultTo(placeholder, 'N/A');
};

export const sanitizeRow = (item, index, columns) => {
  const result = { id: index };
  Object.keys(item).forEach((key) => {
    const placeholder = columns?.find((column) => column.field === key)?.placeholder || 'N/A';
    result[key] = item[key] === null || item[key] === '' ? placeholder : item[key];
  });
  return result;
};

export const removeFmt = (item) => item.replace('Fmt', '');

export const getTableChip = (text, textColor, bgColor) => (
  <Chip
    sx={{ color: textColor, bgcolor: bgColor, fontWeight: 600, padding: '0.25rem 0.5rem', cursor: 'pointer' }}
    label={text.toUpperCase()}
  />
);

export const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

// Convert boolean value to string Yes or No
export const booleanToYN = (value) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (value === 'true') {
    return 'Yes';
  }
  if (value === 'false') {
    return 'No';
  }
  if (value === 'Y') {
    return 'Yes';
  }
  if (value === 'N') {
    return 'No';
  }

  return value;
};

// Format filter chip label
export const formatChipFilterLabel = (filter) => {
  const { field, value } = filter;
  if (value === null) {
    return 'NULL';
  }
  if (value !== null && !isBoolean(value) && !value) {
    return 'NULL';
  }

  if (isArray(value) && value.length) {
    switch (value.length) {
      case 1:
        return booleanToYN(value[0])?.toString().toUpperCase();
      case 2:
        return value.map((value) => value.toString().toUpperCase()).join(', ');
      default:
        return `${value.length} Selected`;
    }
  }

  switch (field) {
    case 'qualityHoldIndicator':
      return value === true || value === 'true' ? 'QUALITY COMPLIANCE HOLD' : 'NO QUALITY COMPLIANCE HOLD';
    case 'damageHoldIndicator':
      return value === true || value === 'true' ? 'DAMAGE HOLD' : 'NO DAMAGE HOLD';
    case 'holdIndicator':
      return value === true || value === 'true' ? 'VEHICLE HOLD' : 'NO VEHICLE HOLD';
    case 'otherHoldIndicator':
      return value === true || value === 'true' ? 'OTHER HOLD' : 'NO OTHER HOLD';
    case 'financeHoldIndicator':
      return value === true || value === 'true' ? 'FINANCE HOLD' : 'NO FINANCE HOLD';
    case 'fleetIndicator':
      return value === true || value === 'true' ? 'FLEET' : 'NON-FLEET';
    case 'portModIndicator':
      return value === true || value === 'true' ? 'QC HOLD' : 'NO QC HOLD';
    case 'reservedIndicator':
      return value === true || value === 'true' ? 'RESERVED' : 'NOT RESERVED';
    case 'unpricedIndicator':
      return value === true || value === 'true' ? 'UNPRICED' : 'PRICED';
    case 'pipelineProtectionIndicator':
      return value === true || value === 'true' ? 'PIPELINE PROTECTION' : 'NO PIPELINE PROTECTION';
    case 'spoDonorIndicator':
      return value === true || value === 'true' ? 'SPO DONOR' : 'NO SPO DONOR';
    case 'presoldIndicator':
      return value === true || value === 'true' ? 'PRESOLD' : 'NOT PRESOLD';
    default:
      return booleanToYN(value)?.toString().toUpperCase();
  }
};

export const getDefaultTable = (tables) => tables.find((item) => item.default === true) || tables[0];

export const getDownloadFields = (columns) =>
  columns
    ?.filter((item) => !item.hide)
    .map((column) => ({
      column: column?.field,
      header: column?.name,
    }));

export const getSorts = (sorts, requiredSorts) => {
  const result = [];

  if (sorts?.length) {
    sorts.forEach((sort) =>
      !sort?.sortFieldReplacers
        ? result.push({ order: sort.order, field: removeFmt(sort.field) })
        : sort.sortFieldReplacers.forEach((item) => result.push({ order: sort.order, field: item })),
    );
  }

  if (!requiredSorts?.length) {
    return result;
  }

  requiredSorts.forEach((item) => {
    const foundSort = sorts.find((sort) => sort.field === item.field);
    if (!foundSort) {
      result.unshift(item);
    }
  });

  return result;
};

export const hasValue = (value) => value !== undefined && value !== null && value !== '';

export const sortColumns = (columns) => {
  const pinnedColumns = columns.filter((column) => column.pinned);
  const simpleColumns = columns.filter((column) => !column.pinned);

  return pinnedColumns.concat(simpleColumns);
};

export const getColumnLabel = (columns, sort) =>
  columns.find((item) => item.field === sort || item.field === `${sort}Fmt`)?.name;

export const getSortBy = (columns, sorts) => {
  if (!sorts.length) {
    return null;
  }

  const label = getColumnLabel(columns, sorts[sorts.length - 1]?.field);

  if (!label) {
    return null;
  }

  return `■ Sort by ${label}`;
};

export const getAllowedConditions = (possibleConditions) => [
  'and',
  'or',
  ...possibleConditions.map((filter) => removeFmt(filter.field)),
];

export const keepAllowedFilters = (currentFilters, allowedConditions) => {
  if (typeof currentFilters !== 'object' || currentFilters === null) {
    return currentFilters;
  }

  if (Array.isArray(currentFilters)) {
    return currentFilters
      .filter((filter) => allowedConditions.includes(Object.keys(filter)[0]))
      .map((item) => keepAllowedFilters(item, allowedConditions))
      .filter((filter) => {
        const value = Object.values(filter)[0];
        return !(Array.isArray(value) && !value.length);
      });
  }

  for (const key in currentFilters) {
    currentFilters[key] = keepAllowedFilters(currentFilters[key], allowedConditions);
  }

  return currentFilters;
};

export const removeInactiveFilters = (filters) => {
  if (!filters?.filter) {
    return filters;
  }
  const operator = Object.keys(filters.filter)[0];
  const filtersCopy = deepCopy(filters);
  const activeFilters = filtersCopy.filter[operator]
    .filter((filterItem) => !filterItem.inactive)
    .map((activeFilterItem) => {
      delete activeFilterItem.inactive;
      return activeFilterItem;
    });
  filtersCopy.filter[operator] = activeFilters;
  return filtersCopy.filter;
};

export const removeInactiveAndAddRequiredFilters = (filters, requiredFilters) => {
  const activeFilters = removeInactiveFilters(filters);
  if (!requiredFilters) {
    return activeFilters;
  }

  if (!activeFilters || !Object.keys(activeFilters).length) {
    return { and: [requiredFilters] };
  }

  return { and: [activeFilters, requiredFilters] };
};

export const getTableMaxHeight = (size, rowHeight = 48) => (size + 1) * rowHeight;

export const getOptionLabel = (field, option, DISTRIBUTOR_MAPPING = []) => {
  if (field === 'fleetIndicator') {
    return option ? 'Fleet' : 'Non-Fleet';
  }

  if (field === 'distributorCode') {
    const distributor = DISTRIBUTOR_MAPPING.find((code) => code.distributorCode === option);
    return distributor ? distributor.distributorName : option;
  }

  return option;
};

export const getGlobalFilterOptions = (filters, DISTRIBUTOR_MAPPING = []) => {
  const result = {};

  Object.entries(filters).forEach(
    ([key, values]) =>
      // TODO: revert key === 'etaStatus' to key once backend is fixed
      (result[key === 'etaStatus' ? 'swEtaStatus' : key] = values.map((value) => ({
        label: getOptionLabel(key, value, DISTRIBUTOR_MAPPING),
        value,
      }))),
  );

  return result;
};

export const areFiltersEmpty = (data) => (data ? Object.values(data).some((value) => value === null) : true);

export const getAllowedViews = (sideNav, region) => {
  if (!region) {
    return sideNav;
  }

  return sideNav.map((navItem) => ({
    ...navItem,
    views: navItem.views.filter((item) => item.view !== 'nationalComparison'),
  }));
};

export const updateEtaStatusField = (globalFilters, pageLens) => {
  if (pageLens === 'CUSTOMER_ETA' && globalFilters.swEtaStatus && globalFilters.swEtaStatus.length > 0) {
    const transformedData = {
      ...globalFilters,
      swCustomerEtaStatus: globalFilters.swEtaStatus,
    };
    delete transformedData.swEtaStatus;
    return transformedData;
  }

  return globalFilters;
};

export const getFullDynamicColumns = (columnSetup, legendMapping) => [
  ...columnSetup.static,
  ...legendMapping.map((status) => ({
    field: status.key,
    headerName: status?.displayLabel ? status.displayLabel : status.label,
    ...columnSetup.dynamic,
  })),
];

export const getDataFilters = (filters, fields = []) => {
  if (!filters) {
    return {};
  }

  const result = Object.entries(filters)
    .filter(([key, value]) => {
      if (fields.length && !fields.includes(key)) {
        return false;
      }

      return !(value.some((item) => item === '__all__') || !value.length);
    })
    .map(([key, value]) => ({ [key]: value }));

  if (result.length) {
    return {
      filter: {
        and: result.map((filter) => {
          const [key, value] = Object.entries(filter)[0];
          return {
            or: value.map((item) => ({ [key]: { eq: item } })),
          };
        }),
      },
    };
  }

  return {};
};

export const getDataTableFilters = (globalFilters, filters) => {
  const result = [];
  const formattedGlobalFilters = getDataFilters(globalFilters);

  if (formattedGlobalFilters.filter) {
    result.push(...formattedGlobalFilters.filter.and);
  }
  if (filters.filter) {
    result.push(...filters.filter.and);
  }

  return result.length ? { filter: { and: result } } : {};
};

export const getTableHeight = (tableData) => {
  if (tableData.error) {
    return '32rem';
  }

  return tableData.data?.length >= 25 && !tableData.loading && 'calc(100vh - 12.125rem)';
};

export const getGlobalFilterSetup = (globalFilters, lens) =>
  globalFilters.map((filter) => (filter?.nameByLens?.[lens] ? { ...filter, name: filter.nameByLens[lens] } : filter));

export const pluralize = (count, original, pluralized) => (count > 1 ? pluralized : original);

export const VIN_SUPPRESSION_STEPS = {
  upload: 'upload',
  check: 'check',
  adminAction: 'adminAction',
  suppression: 'suppression',
  trainingSet: 'trainingSet',
  suppressionAndTrainingSet: 'suppressionAndTrainingSet',
  suppressionReason: 'suppressionReason',
  trainingSetReason: 'trainingSetReason',
};

export const getVinSuppressionMessage = (step, vins = 0, totalVins = 0, reason = '') => {
  switch (step) {
    case VIN_SUPPRESSION_STEPS.upload:
      return (
        <>
          Copy and paste VINs in and hit enter to confirm. Or utilize the uploader to upload a list of VINs by VIN. Then
          proceed to take an admin action on those VINs.
        </>
      );
    case VIN_SUPPRESSION_STEPS.check:
      return (
        <Stack component="span" direction="row" gap={1} alignItems="center">
          <CheckCircleIcon color="success.main" size="medium" sx={{ color: 'success.main' }} />
          <Typography component="span" variant="h6">
            Found <strong>{vins.toLocaleString()}</strong> {pluralize(vins, 'VIN', 'VINs')} out of{' '}
            <strong>{totalVins.toLocaleString()}</strong> Uploaded.
          </Typography>
        </Stack>
      );
    case VIN_SUPPRESSION_STEPS.adminAction:
      return (
        <Stack component="span" gap={3}>
          <Typography component="span" variant="body4">
            How would you like to act on {pluralize(vins, 'this', 'these')} <strong>{vins.toLocaleString()}</strong>{' '}
            {pluralize(vins, 'VIN', 'VINs')}?
          </Typography>
          <Typography component="span" variant="body4">
            Select which type of action you would like to work off of.
          </Typography>
        </Stack>
      );
    case VIN_SUPPRESSION_STEPS.suppression:
      return (
        <>
          Are you sure you want to Suppress/Un-suppress the {pluralize(vins, 'ETA', "ETA's")} of{' '}
          <strong>
            &ldquo;{vins.toLocaleString()} {pluralize(vins, 'VIN', 'VINs')}&rdquo;
          </strong>{' '}
          with the reason <strong>&ldquo;{reason}&rdquo;</strong>? This will trigger an ETA update for{' '}
          {pluralize(vins, THIS_VIN_TXT.single, THIS_VIN_TXT.plural)}.
        </>
      );
    case VIN_SUPPRESSION_STEPS.trainingSet:
      return (
        <>
          Are you sure you want to toggle {pluralize(vins, 'this ', 'these ')}
          <strong>
            &ldquo;{vins.toLocaleString()} {pluralize(vins, 'VIN', 'VINs')}&rdquo;
          </strong>{' '}
          from the ETA training set with the reason <strong>&ldquo;{reason}&rdquo;</strong>? This will update the status
          for {pluralize(vins, THIS_VIN_TXT.single, THIS_VIN_TXT.plural)}.
        </>
      );
    case VIN_SUPPRESSION_STEPS.suppressionAndTrainingSet:
      return (
        <>
          Are you sure you want to Suppress the {pluralize(vins, 'ETA', "ETA's")} and Remove{' '}
          {pluralize(vins, 'ETA', "ETA's")} from Training set of{' '}
          <strong>
            &ldquo;{vins.toLocaleString()} {pluralize(vins, 'VIN', 'VINs')}&rdquo;
          </strong>{' '}
          with the reason <strong>&ldquo;{reason}&rdquo;</strong>? This will trigger an ETA update for{' '}
          {pluralize(vins, THIS_VIN_TXT.single, THIS_VIN_TXT.plural)}.
        </>
      );
    default:
      return null;
  }
};

const COLUMNS_MAP = {};

export const getColumnsCopy = (columns, pathname, viewName = '') => {
  const columnsKey = `${pathname.slice(1) || 'dealer-eta'}-${viewName}`;
  if (!COLUMNS_MAP?.[columnsKey]) {
    COLUMNS_MAP[columnsKey] = deepCopy(columns);
  }
  return COLUMNS_MAP[columnsKey];
};

export const getTextWidth = (text, font = '14px Toyota Type') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font; // Set the font for the context
  return context.measureText(text).width; // Measure the text width
};

const isEmptyObjectOrArray = (value) => {
  if (isObject(value) || isArray(value)) {
    return isEmpty(value);
  }
  return false;
};
export const cleanObject = (item) => {
  if (isArray(item)) {
    // Map and filter out any empty or undefined values
    const cleanedArray = item
      .map(cleanObject)
      .filter((filterItem) => filterItem !== undefined && !isEmptyObjectOrArray(filterItem));
    return cleanedArray.length > 0 ? cleanedArray : []; // Return empty array if cleanedArray is empty
  }
  if (isObject(item)) {
    const cleaned = {};
    Object.keys(item).forEach((key) => {
      const cleanedValue = cleanObject(item[key]);
      if (cleanedValue !== undefined && !isEmptyObjectOrArray(cleanedValue)) {
        cleaned[key] = cleanedValue;
      }
    });
    return Object.keys(cleaned).length > 0 ? cleaned : {};
  }
  return item;
};

export const isChipTooltipEnabled = (value) => isArray(value) && value?.length > 2;

export const getChipTooltipText = (value) => {
  if (!isArray(value)) {
    return null;
  }

  if (value.length > 5) {
    return [...value.slice(0, 5), `+ ${value.length - 5} more`].join(',\n');
  }

  return value.join(', ');
};
