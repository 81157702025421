import React from 'react';
import PropTypes from 'prop-types';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { useAuth } from 'react-oidc-context';
import { getBaseURL } from './utils/environment';

const defaultHttpLink = new HttpLink({
  uri: `https://api-arrival-mdb.${getBaseURL()}/graphql`,
});

const commonHttpLink = new HttpLink({
  uri: `https://api-common.${getBaseURL()}/graphql`,
});

const pipelineHttpLink = new HttpLink({
  uri: `https://api-pipeline-mdb.${getBaseURL()}/graphql`,
});

const ApolloClientProvider = ({ children }) => {
  const { user } = useAuth();

  const authLink = setContext(() => {
    return {
      headers: {
        Authorization: user.access_token,
      },
    };
  });

  const defaultClientLink = authLink.concat(defaultHttpLink);
  const commonClientLink = ApolloLink.split(
    (operation) => operation.getContext().clientName === 'common',
    authLink.concat(commonHttpLink),
  );
  const pipelineClientLink = ApolloLink.split(
    (operation) => operation.getContext().clientName === 'pipeline',
    authLink.concat(pipelineHttpLink),
  );

  const errorLink = onError(({ networkError }) => {
    if (window.location.pathname !== '/authorization' && networkError?.statusCode === 401) {
      window.location.href = '/authorization';
    }
  });

  const composedLink = ApolloLink.from([errorLink, commonClientLink, pipelineClientLink, defaultClientLink]);

  const apolloClient = new ApolloClient({
    link: composedLink,
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

ApolloClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApolloClientProvider;
