import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ApolloClientProvider from './ApolloClientProvider';
import ArrivalProvider from 'contexts/Arrival.context';
import FeaturesProvider from 'contexts/Features.context';
import NavBarProvider from 'contexts/NavBar.context';
import NotificationProvider from 'contexts/Notification.context';
import RolesProvider from 'contexts/Roles.context';
import { getEnv } from './utils/environment';
import Router from './Router';
import theme from './theme';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const forgerockUri = window.location.origin.includes('one.cube.toyota.com')
  ? 'https://ep.fram.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily'
  : 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily';

const oidcConfig = {
  onSigninCallback: () => {
    const pathname = sessionStorage.getItem('cube-pathname') || '/';
    sessionStorage.removeItem('cube-pathname');
    window.history.replaceState(null, '', window.location.origin + pathname);
  },
  authority: forgerockUri,
  client_id: 'DSMETACT',
  redirect_uri: window.location.origin,
  scope: 'openid profile',
};

const env = getEnv();

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  datadogRum.init({
    applicationId: '46f18288-cfb2-4c44-8241-0f1f4c1a23e4',
    clientToken: 'pub4b247c5549d01ca959a30da317eb10c0',
    site: 'datadoghq.com',
    service: 'cube-arrival-ui',
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });

  datadogRum.setGlobalContextProperty('account_id', '091975348311');
  datadogRum.startSessionReplayRecording();
}

const queryClient = new QueryClient();

root.render(
  <AuthProvider {...oidcConfig}>
    <ApolloClientProvider>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <FeaturesProvider>
            <RolesProvider>
              <NavBarProvider>
                <NotificationProvider>
                  <ArrivalProvider>
                    <Router />
                  </ArrivalProvider>
                </NotificationProvider>
              </NavBarProvider>
            </RolesProvider>
          </FeaturesProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ApolloClientProvider>
  </AuthProvider>,
);
